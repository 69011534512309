import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image_mobile = require("../../../assets/img/blogs/ai_vender_blog_header_mob.png");
const header_image = require("../../../assets/img/blogs/ai_vender_blog_header.png");
const section_1 = require("../../../assets/img/blogs/ai_vender_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/ai_vender_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/ai_vender_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/ai_vender_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/ai_vender_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/ai_vender_blog_img_6.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });

  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="How to choose the right AI Vendor for your enterprise?"
        description="Working with the right AI vendor makes a massive difference for your business. Get started with the right AI partner like Workativ to ensure better ROI and fulfillment of your business objectives."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt ai_vender_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-bigger  color-black line-height-18">
                    How to Choose the Right AI Vendor for your Enterprise
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-bigger color-black">
                    How to Choose the
                    <br /> Right AI Vendor for
                    <br /> your Enterprise
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1.Business Level Considerations
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Vendor Considerations
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Technical Requirements
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Ethical Considerations
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. ROI on Implementation
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Workativ: Your Trusted AI Partner
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                The emergence of{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise">
                    {" "}
                    Generative AI
                  </a>
                </span>{" "}
                pushes for rapid adoption of AI like no other technological
                innovations in recent times.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Everyone wants to use Generative AI for its reasoning, logic,
                and problem-solving skills independent of human oversight.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The wave of opportunities Generative AI brings is clear evidence
                that AI or GenAI would take very little time to become
                mainstream.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Companies want to train or build proprietary models for various
                business use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.goldmansachs.com/intelligence/pages/generative-ai-could-raise-global-gdp-by-7-percent.html">
                  Goldman Sachs
                </a>{" "}
                predicts that Generative AI brings up huge economic potential by
                boosting labor productivity, which is expected to be raised by 1
                percentage point annually.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                That’s probably a growth driver for AI. After the release of
                ChatGPT, interest in AI has shot up. With that, Goldman Sach's
                research suggests that{" "}
                <a href="https://www.goldmansachs.com/intelligence/pages/ai-investment-forecast-to-approach-200-billion-globally-by-2025.html">
                  AI investment
                </a>{" "}
                will likely grow by $100 billion in the U.S. and $200 billion
                Globally by 2025.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is evident that companies are eager to implement their own
                GenAI or AI models—irrespective of their desired business
                objectives, whether they want to boost{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-chatbot-for-employee-service">
                  employee support or customer interactions,
                </a>{" "}
                or it can be anything.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, the agony is not all AI projects can successfully meet
                their fate. The reason is finding the right AI vendor is tough.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With so many AI vendors with lots of hype around their
                specializations, it takes time to make the right decision. One
                wrong step could incur losses of millions of dollars for a
                wrong-implemented AI project and prevent you from trying out a
                new opportunity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Before having such a sour experience, let’s get a clear vision
                of how to get the right AI solution built up from your AI
                vendor.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a business leader,&nbsp;
                <a href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai">
                  digital transformation driven by Generative AI&nbsp;
                </a>
                solutions or AI models is always desirable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, choosing the right AI vendor for your enterprise must
                involve evaluating the project's comprehensive sides and
                ensuring that the vendor aligns with overall business
                objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s know them.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Business Level Considerations
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="AI vendor selection -business level considerations"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Your business can have a significant thought process about a new
                project. Before you search for an AI vendor, make sure you have
                business-level considerations.
              </p>
              <h3 className="font-section-sub-header-small">
                Ensure mutual agreement{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses need 100% buy-in from senior management so as to go
                ahead with the AI project or anything for digital transformation
                successfully. If you can have your senior management on the same
                board, it can significantly reduce disagreement regarding any
                decisions taken. Also, with senior management buy-in, it is easy
                for a business to conclude on a project scope and facilitate
                financial support.
              </p>
              <h3 className="font-section-sub-header-small">
                Define your business objectives.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Generative AI generating immense possibilities for various
                areas of business processes, such as automating dunning
                management for late payments,{" "}
                <a href="https://workativ.com/conversational-ai-platform/impact-of-AI">
                  streamlining ITSM,
                </a>{" "}
                augmenting agent efficiency for employee support, or{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search?trk=article-ssr-frontend-pulse_little-text-block">
                  improving enterprise search,
                </a>{" "}
                you must identify the area where you want to apply the power of
                Generative AI and boost productivity and efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As you can gain so much with your AI model, it is critical that
                you have a clear picture of what workflows you want to automate
                for some specific business functions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Know that the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  ‘one-size-fits-all’{" "}
                </span>
                can never align with your business objective. If an AI vendor
                specializes in building workflows for B2B digital payment
                processes for credit purchases, the workflows won’t fit your
                perspective if you want to automate{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  HR operations.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023">
                  HR workflow automation
                </a>{" "}
                is your key objective, it is critical to note down KPIs for each
                process.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, your KPIs can include onboarding workflows, leave
                request management, PTO inquiries, tax issues, etc. Having these
                KPIs ready helps you evaluate the success of AI implementation
                and vendor engagement.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Vendor Considerations
              </h2>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt=" AI vendor considerations for successful AI project"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The surge of Generative AI applications for repetitive processes
                results in business leaders adopting AI solutions at scale. This
                is a golden moment for vendors to apply Generative AI to their
                legacy products or rebrand them to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  drive maximum business profits.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is essentially important for businesses to understand if a
                vendor has just emerged as an AI player or is an existing
                player, among many other critical things.
              </p>
              <h3 className="font-section-sub-header-small">
                The breakdown of technical barriers
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                AI is a tough discipline. What seems easy to AI experts may seem
                perplexing to business executives because they possess expertise
                in business statistics, not complex technical language.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Things can be misleading if executives aren’t familiar with
                machine learning or NLP terminologies, such as
                hyperparameterization, algorithms, epochs, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Simultaneously, various ML platforms have their specific
                language for features or processes, whereas they also consist of
                familiar language or concepts.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Business leaders struggle to find a common language to connect
                and feel comfortable.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is where businesses need to bridge the gap between AI
                experts and business executives by simplifying complex AI
                language.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, finding an interpreter who can help address this
                challenge is painful.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also, this is an additional investment burden on top of your
                overall project spend.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is crucial that your AI vendor keeps things simple for you or
                uses simple language so that you can understand and clear
                doubts.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Paying attention to simplifying complex language is also
                essential to having fewer errors and faster implementation.
              </p>
              <h3 className="font-section-sub-header-small">
                Experience and viability in the market
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The sudden influx of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-security">
                  ChatGPT-like technology
                </a>{" "}
                or Generative has companies emerging as AI vendors.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                The list includes many non-AI companies. Surprisingly, database
                companies and even finance leaders consider themselves AI
                specialists.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is a serious area to consider as you must ensure the
                expertise you need aligns with your business objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                From that perspective, real-world experience with{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/implement-generative-ai-knowledge-base">
                  AI project implementation
                </a>{" "}
                is essential.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Consider AI vendor viability"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Things you need to ensure before collaborating are to—
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18">
                  Know the number of years the AI startup or company exists in
                  the market
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  How many projects has the company successfully implemented
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  What specializations does it possess to implement the
                  requirements for your use cases
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Check if they are listed on Crunchbase to ensure viability
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Go through customer reviews on G2 to get some initial idea
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With all these insights in your kitty, you can optimize your
                time and better understand who to choose.
              </p>
              <h3 className="font-section-sub-header-small">
                Modern–scalable and elastic architecture
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Legacy or clunky architecture is less flexible to scale with
                evolving needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also, as your needs change, your computing power changes.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Understand how on-premise architecture and private or public
                clouds can add to your computing billing.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The best idea is to look for a portable cloud, either with an
                independent cloud architecture or a combination of two—cloud and
                on-prem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let’s also take into consideration that if you change the number
                of use cases as your need evolves, your{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  AI platform
                </a>{" "}
                must support scalability. Let’s also take into consideration
                that if you change the number of use cases as your need evolves,
                your AI platform must support scalability.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Choose a platform that scales and supports as many use cases as
                you want to implement without the heavy lifting.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                On top of that, choosing a platform that requires no tough
                learning curve to deploy and manage is always better.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                You guess it right. We are talking about a{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-support-human">
                  no-code AI platform for AI solutions
                </a>{" "}
                to streamline your business processes.
              </p>
              <h3 className="font-section-sub-header-small">
                AI customer support
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                AI is hard. It is a simple theory. Working with new technology
                and workflows can be confusing and frustrating due to several
                glitches.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Maybe initially, your AI solutions can fail if bugs are
                persistent. Ensure your AI providers help you fix bug issues
                without doing this alone.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                The other way is that AI solutions can degrade with time. It is
                essential to look into the details so your AI partner can
                support you even after months and help you improve
                underperforming models.
              </p>
              <h3 className="font-section-sub-header-small">
                Commitment to customer success
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Not every business can hinge upon typical AI solutions. Your
                business needs unique solutions that typical AI solutions cannot
                meet.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The concern is that AI startups can tell you that their product
                can easily solve your problem.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Seek an AI vendor that can adjust to your problem and create
                custom solutions that suit your business objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Aside from that, acquisition or emerging needs on your business
                front can sometimes force you to transition to a flexible
                platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ensure you avoid falling prey to vendor lock-in, which can
                either ask for extra fees to move out of the present platform or
                cause you to lose control of your data in the existing platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Partner with an AI vendor committed to your success and helping
                you transition seamlessly.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Technical Requirements
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="AI vendor selection - technical considerations"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Your AI solution can deliver outcomes only when it meets some of
                the essential technical requirements for your internal business
                processes. Let’s know them.
              </p>
              <h3 className="font-section-sub-header-small">
                Integrations and tech stack
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The AI solutions or workflows you want to implement for various
                business use cases must talk to your existing tech stack, build
                a unified connection, and offer seamless{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  problem-solving flexibility.
                </span>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example,{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  to streamline HR workflows,
                </a>{" "}
                an AI platform must integrate with existing HR platforms, such
                as BambooHR, SAP SuccessFactors, ServiceNow, etc, to help build
                convenient workflows accessible via familiar communication
                platforms like MS Teams, Slack, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Check with your AI vendor to see if they can enable you to
                create a flexible integrations with your existing tech stacks,
                handle configurations, and help you get started.
              </p>
              <h3 className="font-section-sub-header-small">
                Model training for implementation
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                AI model training can include several factors, including data
                source, training responsibility, data authenticity, testing,
                etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ask your vendor if it is your responsibility to train AI models.
                If it is your part of the job, you must build an engineering
                process with significant investment in human capital.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In addition to DIY model training, it can also involve
                additional work for your people if your vendor uses multiple
                application packages to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  build an AI model.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Ensure that the testing is part of your vendor’s job. On the
                other hand, data validation is a collaborative effort.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Ethical Considerations
              </h2>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="AI vendor selection -ethical considerations"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI and its related disciplines are known for their
                nuance behavior. They can hallucinate or demonstrate unbiased
                phenomena if data is scarce or inappropriate.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                You must take care of the ethical side of your AI model
                development.
              </p>

              <h3 className="font-section-sub-header-small">
                Data privacy and security
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                An AI model built on a transparent policy helps create a safe
                and secure environment for everyone.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ask your AI vendor what methods they use to protect your data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Data leakage of company policies, contract agreements, or
                details on new projects can be a treasure trove for scammers to
                unleash reputational threats and incur financial risks for your
                business.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Before you start, ensure your AI project is end-to-end
                encrypted, multi-factor authentication is implemented, etc.
              </p>
              <h3 className="font-section-sub-header-small">
                Data governance and compliance
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                An AI vendor that ensures data governance and compliance with
                local and international regulation systems can provide complete
                data protection.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ensure that your AI vendor offers compliance with many
                regulatory systems, such as GDPR and other compliance standards.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                ROI on Implementation
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt=" AI vendor selection -ROI on Implementation  "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                What’s the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-service-desk-cost-savings">
                  return on your investment in AI solutions?
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Ask your vendor this essential question.
              </p>
              <h3 className="font-section-sub-header-small">
                Untimely delivery can have a significant impact on your project
                if it doesn’t arrive in time.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                By the time you launch your solution, your competitor will
                likely build a positive impact and drive significant growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Ensure you can have your project launched in the expected time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Besides, what are the quantified and unquantified benefits for
                your business?
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Whereas quantified advantages are concerned, check what
                percentage of repetitive work you can reduce and optimize
                productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the unquantified benefit side, ensure you can build user
                experience and improve efficiency.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                If your vendor can help you gain these benefits, your AI will
                drive meaningful ROI for your business.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workativ: Your Trusted AI Partner
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                At{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  Workativ,
                </a>{" "}
                we are committed to helping you solve your business problems
                most effectively and effortlessly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Although our no-code platform is easy to use and fast to train,
                we help you end-to-end, from model training to deployment.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Whether you need technical support or seamless customer
                onboarding, you can swear by our support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We can guarantee the high performance of your AI models with our
                microcontainer deployment models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We use microcontainer deployment models to provide computing
                flexibility while ensuring the high performance of the AI models
                you seek from a containerized and virtualized architecture.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On our security, we offer top-notch security assurance with
                multifactor authentication, SSH authentication, and SSL
                encryption.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Over the years, we have garnered proven track record of helping
                enterprises with their unique IT problems using our
                conversational AI chatbots, which now encompass the power of
                Generative AI to augment the existing level of automation and
                boost performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                AI is definitely tough. But we make it easy to understand AI
                better and help you leverage it easily without confusion.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To start your AI project, refer to these essential tips.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to learn about us and implement your AI solutions,{" "}
                <a href="https://workativ.com/contact-us">talk to us today.</a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1.Business Level Considerations
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Vendor Considerations
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Technical Requirements
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Ethical Considerations
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. ROI on Implementation
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Workativ: Your Trusted AI Partner
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                The emergence of{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise">
                    {" "}
                    Generative AI
                  </a>
                </span>{" "}
                pushes for rapid adoption of AI like no other technological
                innovations in recent times.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Everyone wants to use Generative AI for its reasoning, logic,
                and problem-solving skills independent of human oversight.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The wave of opportunities Generative AI brings is clear evidence
                that AI or GenAI would take very little time to become
                mainstream.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Companies want to train or build proprietary models for various
                business use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.goldmansachs.com/intelligence/pages/generative-ai-could-raise-global-gdp-by-7-percent.html">
                  Goldman Sachs
                </a>{" "}
                predicts that Generative AI brings up huge economic potential by
                boosting labor productivity, which is expected to be raised by 1
                percentage point annually.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                That’s probably a growth driver for AI. After the release of
                ChatGPT, interest in AI has shot up. With that, Goldman Sach's
                research suggests that{" "}
                <a href="https://www.goldmansachs.com/intelligence/pages/ai-investment-forecast-to-approach-200-billion-globally-by-2025.html">
                  AI investment
                </a>{" "}
                will likely grow by $100 billion in the U.S. and $200 billion
                Globally by 2025.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is evident that companies are eager to implement their own
                GenAI or AI models—irrespective of their desired business
                objectives, whether they want to boost{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-chatbot-for-employee-service">
                  employee support or customer interactions,
                </a>{" "}
                or it can be anything.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, the agony is not all AI projects can successfully meet
                their fate. The reason is finding the right AI vendor is tough.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With so many AI vendors with lots of hype around their
                specializations, it takes time to make the right decision. One
                wrong step could incur losses of millions of dollars for a
                wrong-implemented AI project and prevent you from trying out a
                new opportunity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Before having such a sour experience, let’s get a clear vision
                of how to get the right AI solution built up from your AI
                vendor.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a business leader,&nbsp;
                <a href="https://workativ.com/conversational-ai-platform/blog/digital-transformation-generative-ai">
                  digital transformation driven by Generative AI&nbsp;
                </a>
                solutions or AI models is always desirable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, choosing the right AI vendor for your enterprise must
                involve evaluating the project's comprehensive sides and
                ensuring that the vendor aligns with overall business
                objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s know them.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Business Level Considerations
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="AI vendor selection -business level considerations"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Your business can have a significant thought process about a new
                project. Before you search for an AI vendor, make sure you have
                business-level considerations.
              </p>
              <h3 className="font-section-sub-header-small">
                Ensure mutual agreement{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses need 100% buy-in from senior management so as to go
                ahead with the AI project or anything for digital transformation
                successfully. If you can have your senior management on the same
                board, it can significantly reduce disagreement regarding any
                decisions taken. Also, with senior management buy-in, it is easy
                for a business to conclude on a project scope and facilitate
                financial support.
              </p>
              <h3 className="font-section-sub-header-small">
                Define your business objectives.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Generative AI generating immense possibilities for various
                areas of business processes, such as automating dunning
                management for late payments,{" "}
                <a href="https://workativ.com/conversational-ai-platform/impact-of-AI">
                  streamlining ITSM,
                </a>{" "}
                augmenting agent efficiency for employee support, or{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search?trk=article-ssr-frontend-pulse_little-text-block">
                  improving enterprise search,
                </a>{" "}
                you must identify the area where you want to apply the power of
                Generative AI and boost productivity and efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As you can gain so much with your AI model, it is critical that
                you have a clear picture of what workflows you want to automate
                for some specific business functions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Know that the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  ‘one-size-fits-all’{" "}
                </span>
                can never align with your business objective. If an AI vendor
                specializes in building workflows for B2B digital payment
                processes for credit purchases, the workflows won’t fit your
                perspective if you want to automate{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  HR operations.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023">
                  HR workflow automation
                </a>{" "}
                is your key objective, it is critical to note down KPIs for each
                process.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, your KPIs can include onboarding workflows, leave
                request management, PTO inquiries, tax issues, etc. Having these
                KPIs ready helps you evaluate the success of AI implementation
                and vendor engagement.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Vendor Considerations
              </h2>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt=" AI vendor considerations for successful AI project"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The surge of Generative AI applications for repetitive processes
                results in business leaders adopting AI solutions at scale. This
                is a golden moment for vendors to apply Generative AI to their
                legacy products or rebrand them to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  drive maximum business profits.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is essentially important for businesses to understand if a
                vendor has just emerged as an AI player or is an existing
                player, among many other critical things.
              </p>
              <h3 className="font-section-sub-header-small">
                The breakdown of technical barriers
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                AI is a tough discipline. What seems easy to AI experts may seem
                perplexing to business executives because they possess expertise
                in business statistics, not complex technical language.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Things can be misleading if executives aren’t familiar with
                machine learning or NLP terminologies, such as
                hyperparameterization, algorithms, epochs, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Simultaneously, various ML platforms have their specific
                language for features or processes, whereas they also consist of
                familiar language or concepts.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Business leaders struggle to find a common language to connect
                and feel comfortable.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is where businesses need to bridge the gap between AI
                experts and business executives by simplifying complex AI
                language.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, finding an interpreter who can help address this
                challenge is painful.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also, this is an additional investment burden on top of your
                overall project spend.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is crucial that your AI vendor keeps things simple for you or
                uses simple language so that you can understand and clear
                doubts.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Paying attention to simplifying complex language is also
                essential to having fewer errors and faster implementation.
              </p>
              <h3 className="font-section-sub-header-small">
                Experience and viability in the market
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The sudden influx of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-security">
                  ChatGPT-like technology
                </a>{" "}
                or Generative has companies emerging as AI vendors.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                The list includes many non-AI companies. Surprisingly, database
                companies and even finance leaders consider themselves AI
                specialists.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is a serious area to consider as you must ensure the
                expertise you need aligns with your business objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                From that perspective, real-world experience with{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/implement-generative-ai-knowledge-base">
                  AI project implementation
                </a>{" "}
                is essential.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Consider AI vendor viability"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Things you need to ensure before collaborating are to—
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18">
                  Know the number of years the AI startup or company exists in
                  the market
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  How many projects has the company successfully implemented
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  What specializations does it possess to implement the
                  requirements for your use cases
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Check if they are listed on Crunchbase to ensure viability
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Go through customer reviews on G2 to get some initial idea
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With all these insights in your kitty, you can optimize your
                time and better understand who to choose.
              </p>
              <h3 className="font-section-sub-header-small">
                Modern–scalable and elastic architecture
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Legacy or clunky architecture is less flexible to scale with
                evolving needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Also, as your needs change, your computing power changes.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Understand how on-premise architecture and private or public
                clouds can add to your computing billing.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The best idea is to look for a portable cloud, either with an
                independent cloud architecture or a combination of two—cloud and
                on-prem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let’s also take into consideration that if you change the number
                of use cases as your need evolves, your{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  AI platform
                </a>{" "}
                must support scalability. Let’s also take into consideration
                that if you change the number of use cases as your need evolves,
                your AI platform must support scalability.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Choose a platform that scales and supports as many use cases as
                you want to implement without the heavy lifting.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                On top of that, choosing a platform that requires no tough
                learning curve to deploy and manage is always better.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                You guess it right. We are talking about a{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-support-human">
                  no-code AI platform for AI solutions
                </a>{" "}
                to streamline your business processes.
              </p>
              <h3 className="font-section-sub-header-small">
                AI customer support
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                AI is hard. It is a simple theory. Working with new technology
                and workflows can be confusing and frustrating due to several
                glitches.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Maybe initially, your AI solutions can fail if bugs are
                persistent. Ensure your AI providers help you fix bug issues
                without doing this alone.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                The other way is that AI solutions can degrade with time. It is
                essential to look into the details so your AI partner can
                support you even after months and help you improve
                underperforming models.
              </p>
              <h3 className="font-section-sub-header-small">
                Commitment to customer success
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Not every business can hinge upon typical AI solutions. Your
                business needs unique solutions that typical AI solutions cannot
                meet.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The concern is that AI startups can tell you that their product
                can easily solve your problem.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Seek an AI vendor that can adjust to your problem and create
                custom solutions that suit your business objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Aside from that, acquisition or emerging needs on your business
                front can sometimes force you to transition to a flexible
                platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ensure you avoid falling prey to vendor lock-in, which can
                either ask for extra fees to move out of the present platform or
                cause you to lose control of your data in the existing platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Partner with an AI vendor committed to your success and helping
                you transition seamlessly.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Technical Requirements
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="AI vendor selection - technical considerations"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Your AI solution can deliver outcomes only when it meets some of
                the essential technical requirements for your internal business
                processes. Let’s know them.
              </p>
              <h3 className="font-section-sub-header-small">
                Integrations and tech stack
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The AI solutions or workflows you want to implement for various
                business use cases must talk to your existing tech stack, build
                a unified connection, and offer seamless{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  problem-solving flexibility.
                </span>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example,{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  to streamline HR workflows,
                </a>{" "}
                an AI platform must integrate with existing HR platforms, such
                as BambooHR, SAP SuccessFactors, ServiceNow, etc, to help build
                convenient workflows accessible via familiar communication
                platforms like MS Teams, Slack, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Check with your AI vendor to see if they can enable you to
                create a flexible integrations with your existing tech stacks,
                handle configurations, and help you get started.
              </p>
              <h3 className="font-section-sub-header-small">
                Model training for implementation
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                AI model training can include several factors, including data
                source, training responsibility, data authenticity, testing,
                etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ask your vendor if it is your responsibility to train AI models.
                If it is your part of the job, you must build an engineering
                process with significant investment in human capital.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In addition to DIY model training, it can also involve
                additional work for your people if your vendor uses multiple
                application packages to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  build an AI model.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Ensure that the testing is part of your vendor’s job. On the
                other hand, data validation is a collaborative effort.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Ethical Considerations
              </h2>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="AI vendor selection -ethical considerations"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI and its related disciplines are known for their
                nuance behavior. They can hallucinate or demonstrate unbiased
                phenomena if data is scarce or inappropriate.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                You must take care of the ethical side of your AI model
                development.
              </p>

              <h3 className="font-section-sub-header-small">
                Data privacy and security
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                An AI model built on a transparent policy helps create a safe
                and secure environment for everyone.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ask your AI vendor what methods they use to protect your data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Data leakage of company policies, contract agreements, or
                details on new projects can be a treasure trove for scammers to
                unleash reputational threats and incur financial risks for your
                business.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Before you start, ensure your AI project is end-to-end
                encrypted, multi-factor authentication is implemented, etc.
              </p>
              <h3 className="font-section-sub-header-small">
                Data governance and compliance
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                An AI vendor that ensures data governance and compliance with
                local and international regulation systems can provide complete
                data protection.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ensure that your AI vendor offers compliance with many
                regulatory systems, such as GDPR and other compliance standards.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                ROI on Implementation
              </h2>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt=" AI vendor selection -ROI on Implementation  "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                What’s the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-service-desk-cost-savings">
                  return on your investment in AI solutions?
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Ask your vendor this essential question.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Untimely delivery can have a significant impact on your project
                if it doesn’t arrive in time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By the time you launch your solution, your competitor will
                likely build a positive impact and drive significant growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Ensure you can have your project launched in the expected time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Besides, what are the quantified and unquantified benefits for
                your business?
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Whereas quantified advantages are concerned, check what
                percentage of repetitive work you can reduce and optimize
                productivity.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the unquantified benefit side, ensure you can build user
                experience and improve efficiency.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                If your vendor can help you gain these benefits, your AI will
                drive meaningful ROI for your business.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workativ: Your Trusted AI Partner
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                At{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  Workativ,
                </a>{" "}
                we are committed to helping you solve your business problems
                most effectively and effortlessly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Although our no-code platform is easy to use and fast to train,
                we help you end-to-end, from model training to deployment.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Whether you need technical support or seamless customer
                onboarding, you can swear by our support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We can guarantee the high performance of your AI models with our
                microcontainer deployment models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We use microcontainer deployment models to provide computing
                flexibility while ensuring the high performance of the AI models
                you seek from a containerized and virtualized architecture.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On our security, we offer top-notch security assurance with
                multifactor authentication, SSH authentication, and SSL
                encryption.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Over the years, we have garnered proven track record of helping
                enterprises with their unique IT problems using our
                conversational AI chatbots, which now encompass the power of
                Generative AI to augment the existing level of automation and
                boost performance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                AI is definitely tough. But we make it easy to understand AI
                better and help you leverage it easily without confusion.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To start your AI project, refer to these essential tips.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to learn about us and implement your AI solutions,{" "}
                <a href="https://workativ.com/contact-us">talk to us today.</a>
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-trends-2024"
              className="font-section-normal-text-testimonials"
            >
              Top ITSM Trends in 2024
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/ai-co-pilot-it-operations
              "
              className="font-section-normal-text-testimonials"
            >
              The Role of AI Co-pilots in IT Operations
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/onboarding-challenges-it-service-desk-face-with-new-agents"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Onboarding Challenges IT Service Desk Face with New Agents
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
